<template>
    <div style="background: #F5F5F5; padding-top: 30px;">

        <div class="brand">
            <dl class="brandItem clearfix pr" v-for="(item,index) in brandData" :key="index">
                <dt  style="width: 330px;height: 330px;" class="fl">
                    <img :src="routerBack[index]" alt="">
                    <!-- <img :src="imgPath+item.img_url" alt=""> -->
                </dt>
                <dd v-for="(ite,index) in item.goodsList" v-if="index < 3" :key="index">
                    <GoodsItem  :imgWidth="200+'px'" :comeStyle="comeStyle" :goodsDetail="ite" ></GoodsItem>
                    <i></i>
                </dd>
                <div class="lookMore" @click="goBrandList(item.rid)">
                    <p>查看更多</p>
                </div>
            </dl>
        </div>
        <!--   底部    -->
        <myFooter></myFooter>
    </div>
</template>
<style lang="less" scoped>
    dl{
        transform: translateZ(0);
    }
    .brand{
        min-height: 700px;
    }
    .brandItem {
        width: 1200px;
        height: 330px;
        margin: 0 auto 30px;
        background: #fff;

        dt{
            img{
                width: 330px;
                height: 330px;
                border: 1px solid #ddd;
            }
        }
        dd{
            transform: translateZ(0);
            padding-top: 21px;
            padding-left: 39px;
            padding-right: 40px;
            position: relative;
            float: left;
            i{
                width: 1px;
                height: 270px;
                position: absolute;
                right: 0;
                top:0;
                background: url(../../assets/images/img_line.png) 0 0 no-repeat;
            }
        }
    }
    .page_a{
        padding-bottom: 40px;
    }

    .lookMore{
        width: 38px;
        height:109px;
        cursor: pointer;
        background:rgba(0,0,0,1);
        opacity:0.6;
        border-radius:4px 0px 0px 4px;
        position: absolute;
        right: 0;
        top:111px;
        color: #999999;
       font-size: 14px;
        p{
            word-wrap: break-word;
            width: 14px;
            line-height: 16px;
            margin: 15px auto 0;
        }
    }

</style>
<script>
    import GoodsItem from '@/components/GoodsItem.vue'
    import footer from '@/components/footer'
    import {getBrand} from '@/views/brand/brandApi'
    import {getBrandGoodsbrand } from '@/global/https'
    export default {
          components: {
                GoodsItem,
              myFooter:footer, // 底部
          },
          data(){
                return{
                    routerBack:[
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df2034b7bbc1.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df203afda562.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df203fcec9dd.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df20441b7dc0.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df2048f37893.jpg',
                    'https://7dd-statics.oss-cn-beijing.aliyuncs.com/data/files/recommend/20191212/5df204c14b722.jpg',
                  ],
                  comeStyle:{
                        alignCent:'center'
                  },
                  brandData:[]
                }
          },
          mounted(){
                this.getBrand();
          },
          methods:{
                async getBrand(){
                      let {code,msg,data}= await getBrandGoodsbrand({});
                      console.log(data)
                            if(code == 0){
                                  this.brandData = data;
                            }
                },
                goBrandList(id){
                      this.$router.push({path:'/brandList',query:{brandId:id,type:2}})
                }
          }
    }
</script>
